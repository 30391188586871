/*
 * @Author: wangqs 
 * @description 完善基本信息
 * @Date: 2021-07-11 16:24:29 
 * @Last Modified by: wangqs
 * @Last Modified time: 2022-01-26 14:41:37
 */
<template>
  <div class="page-box box">
    <div class="top-box">
      <div class="text-a">
        完善基本信息
      </div>
      <div class="text-b">
        完善信息，以便于您进行分期业务
      </div>
    </div>

    <!--  -->
    <div
      class="box"
      style="padding-top: 10px;"
    >
      <van-field
        v-model="userInfo.userName"
        required
        label="姓名"
        placeholder="请输入姓名"
      />
      <van-field
        v-model="userInfo.idCardNo"
        required
        label="身份证号码"
        placeholder="请输入本人身份证号码"
      />
      <!-- 允许输入正整数，调起纯数字键盘 -->
      <van-field
        v-model="userInfo.bankCardNo"
        required
        type="digit"
        placeholder="请输入储蓄卡号码"
        label="储蓄卡号码"
      />
      <!-- 输入手机号，调起手机号键盘 -->
      <van-field
        v-model="userInfo.mobile"
        required
        type="tel"
        placeholder="输入银行预留手机号"
        label="预留手机号"
      />
      <!-- 提交 -->
      <van-button
        class="submit-btn"
        color="#51B464"
        round
        type="info"
        @click="nextFun"
      >
        下一步
      </van-button>
    </div>

    <!-- code 码 -->
    <van-overlay
      style="background: rgba(0,0,0,0.3)"
      :show="show"
      @click="show = false"
    >
      <div
        class="wrapper-box"
        @click.stop="show = false"
      >
        <div
          class="block-box"
          @click.stop
        >
          <div class="text-a">
            请填写手机短信验证码
          </div>
          <div class="text-b">
            已发送到{{ userInfo.mobile }}
          </div>
          <van-field
            v-model="code"
            maxlength="6"
            class="text-c"
            label="验证码"
          />
          <van-button
            class="code-btn"
            color="#51B464"
            round
            type="info"
            @click="bindCardBtn"
          >
            提交
          </van-button>
        </div>
      </div>
    </van-overlay>
    <form
      :action="faceData.action || 'https://dsptest.cpcn.com.cn/DSPLivenessWeb/InterfaceVI'"
      name="faceForm"
      style="opacity: 0;position: absolute;left: -2000px;top:0;"
      method="POST"
    >
      <div>
        <input
          type="text"
          name="message"
          placeholder="message"
          :value="faceData.message"
        />
      </div>
      <div>
        <input
          type="text"
          name="signature"
          placeholder="signature"
          :value="faceData.signature"
        />
      </div>
      <div>
        <input
          id="dgtlEnvlp"
          type="text"
          name="dgtlenvlp"
          placeholder="dgtlenvlp"
          :value="faceData.dgtlenvlp"
        />
      </div>
      <div>
        <input
          id="signSN"
          type="text"
          name="signSN"
          placeholder="signSN"
          :value="faceData.signSN"
        />
      </div>
      <div>
        <input
          id="encryptSN"
          type="text"
          name="encryptSN"
          placeholder="encryptSN"
          :value="faceData.encryptSN"
        />
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'BasicInfo',
  components: {
  },
  data () {
    return {
      show: false,
      userInfo: {
        userName: '',
        bankCardNo: '',
        idCardNo: '',
        mobile: ''
      },
      checkInfo: {},
      code: '',
      requestNo: '',
      faceData: {}
    }
  },
  computed: {
  },
  created () {
    this.checkInfo = JSON.parse(window.localStorage.getItem('userCheckInfo') || '{}')
    if (this.checkInfo.bindCard === 0) {
      this.getUserInfo()
    } else {
      this.userInfo = JSON.parse(window.localStorage.getItem('orderInfo') || '{}')
    }
    window.localStorage.setItem('faceOrderNo', this.$route.query.orderNo || '')
  },
  methods: {
    async getUserInfo () {
      let res = await this.$http.orderApi.getOrderInfo({courseNo: this.$route.query.id})
      console.log(res, 'resb')
      this.userInfo = res.msg || {}
    },
    // 校验参数
    filterParams () {
      let data = {
        userName: this.userInfo.userName,
        bankAccountNo: this.userInfo.bankCardNo,
        idCardNo: this.userInfo.idCardNo,
        mobile: this.userInfo.mobile
      }
      if (!data.bankAccountNo) {
        this.$toast('请填写银行卡号')
        return false
      } else if (!data.idCardNo) {
        this.$toast('请填写身份证号')
        return false
      } else if (!data.mobile) {
        this.$toast('请填写手机号')
        return false
      } else if (!data.userName) {
        this.$toast('请填写姓名')
        return false
      } else {
        return data
      }
    },
    async nextFun () {
      if (this.userInfo.mobile.length !== 11) {
        this.$toast('请输入正确手机号')
        return
      }
      if (this.checkInfo.bindCard === 1) {
        let data = this.filterParams()
        if (data) {
          this.$toast.loading({
            message: '加载中',
            forbidClick: true
          });

          let res = await this.$http.userApi.bankBind(data)
          if (res.code === 200) {
            this.$toast.clear()
            this.show = true
            this.requestNo = res.msg.requestNo || ''
          }
        }
      } else {
        // 人脸识别校验
        let res = await this.$http.orderApi.orderFaceCheck({id: this.$route.query.orderNo || '1', courseNo: this.$route.query.id})
        if (res.code === 200) {
          if (res.msg.face) {
            if (res.msg.faceNo) {
              let resB = await this.$http.orderApi.orderFaceParams({id:res.msg.faceNo})
              console.log(resB, '===')
              if (resB.code === 200) {
                this.faceData = resB.msg || {}
                this.$nextTick(() => {
                  document.faceForm && document.faceForm.submit()
                })
              }
            }
          } else {
            window.localStorage.setItem('orderInfo', JSON.stringify(this.userInfo))
            this.$router.push('/school/authInfo?id=' + this.$route.query.id)
          }
        }
      }
    },
    async bindCardBtn () {
      if (!this.code) {
        this.$toast('请填写验证码')
      } else {
        let data = {
          requestNo: this.requestNo,
          smsCode: this.code
        }
        this.$toast.loading({
          message: '加载中',
          forbidClick: true
        });
        let res = await this.$http.userApi.bankBindConfirm(data)
        if (res.code === 200) {
          this.$toast.success('绑定成功,自动进入下一步')
          setTimeout(() => {
            window.localStorage.setItem('orderInfo', JSON.stringify(this.userInfo))
            this.$router.push('/school/authInfo?id=' + this.$route.query.id)
          }, 1500);
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  .top-box {
    height: 166px;
    background: url('~@/assets/img/page-bg.png') center center no-repeat;
    background-size: 100% 192px;
    padding-top: 88px;
    padding-left: 15px;
    .text-a {
      font-size: 17px;
      font-weight: bold;
      line-height: 40px;
    }
    .text-b {
      color: #7c7c7c;
      line-height: 16px;
      font-size: 13px;
    }
  }
  .submit-btn {
    width: 345px;
    margin: 65px auto 0;
    display: block;
  }
  
}
</style>
